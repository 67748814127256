.Container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: calc(100% - 30px);
}

@media(min-width: 576px) {
    .Container {
        max-width: 540px;
    }
}

@media(min-width: 768px) {
    .Container {
        max-width: 720px;
    }
}

@media(min-width: 992px) {
    .Container {
        max-width: 960px;
    }
}

@media(min-width: 1200px) {
    .Container {
        max-width: 1140px;
    }
}

@media(min-width: 1500px) {
    .Container.Wide {
        max-width: 1400px;
    }
}