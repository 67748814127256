body {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

h2 {
    margin: 10px 0;
}

.Invalid {
    border: 2px solid red !important;
}

.ContentBlock {
    margin: 30px 0;
}

.UploadButton {
    border: solid 2px #50BE4B;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;

    a {
        padding: 8px 15px;
        text-decoration: none;
        color: #000;
        font-size: 16px;
        line-height: 35px;
    }
}

.UploadButton:hover {
    background: #50BE4B;

    a {
        color: #fff;
    }
}

.Video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 30px;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.CategoryList {
    .fa-chevron-down, .fa-chevron-up {
        margin-right: 8px;
        cursor: pointer;
    }

    .Level-1 {
        cursor: pointer;
    }

    .Level-2 {
        padding-left: 40px;
    }

    .Level-3 {
        padding-left: 60px;
    }
}

.ContentBlock.Hidden {
    display: none;
}

.OptionButton.Hidden {
    display: none;
}

.Guide {
    .Tags {
        a {
            margin-right: 5px;
        }
    }

    .ContentBlock {
        margin: 30px 0;
    }

    .Hidden {
        display: none;
    }
}

.OptionButton {
    color: #000;
    background: #fff;
    border: 3px solid #000000;
    margin-right: 15px;
    font-size: 18px;
    padding: 12px 18px;
    border-radius: 5px;
    cursor: pointer;
}

.OptionButton.Vihreä{
    border: 3px solid #50BE4B;
}

.OptionButton.Punainen {
    border: 3px solid #FF5A43;
}

.OptionButton.Musta {
    border: 3px solid #000000;
}

.OptionButton.Vihreä:hover, .OptionButton.Vihreä.Selected {
    color: #fff;
    background: #50BE4B;
}

.OptionButton.Punainen:hover, .OptionButton.Punainen.Selected {
    color: #fff;
    background: #FF5A43;
}

.OptionButton.Musta:hover, .OptionButton.Musta.Selected {
    color: #fff;
    background: #000000;
}

.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #000;
    opacity: 0.7;
}

.Popup {
    background: #fff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    width: 100%;
    max-width: 800px;
    padding: 30px 60px;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    z-index: 100;

    .Close {
        background: none;
        border: none;
        box-shadow: none;
        color: #333333;
        font-size: 12px;
        position: absolute;
        right: 60px;
        top: 15px;
        cursor: pointer;
    }
}

.GuideFilters {
    .Select {
        max-width: 150px;
        margin-top: 15px;
        margin-right: 15px;
        float: left;
    }
}

.fa-thumbs-up {
    font-size: 40px;
    color: #50BE4B;
}

.CheckboxContainer {
    overflow: auto;

    @media(min-width: 900px) {
        .Checkbox {
            width: 50%;
            float: left;
        }
    }

    @media(min-width: 1200px) {
        .Checkbox {
            width: 33%;
            float: left;
        }
    }
}

.GuideForm, .ProductForm {
    
    .AddBlocksWrapper {
        @media(min-width: 1200px) {
            padding: 15px;
            margin-bottom: 30px;
            border-radius: 5px;
            position: fixed;
            width: 130px;
        }
    }

    @media(max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;

        .SidebarLeftWrapper {
            order: 2;
        }

        .MainContentWrapper {
            order: 3;
        }

        .SidebarWrapper {
            order: 1;
        }
    }
}

.SearchField {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 15px;
}