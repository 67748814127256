.TextBlock {
    margin-bottom: 1rem;
    padding: 0 15px 15px;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.15);

    .label {
        display: inline-block;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }

    .mce-content-body {
        min-height: 150px;
        padding: 5px;
    }

    .fa-arrows-alt {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}