.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 15px;
    background: #fff;
    padding: 15px 0;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        a {
            margin-right: 5px;
        }
    }
}

.table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    padding: 8px;
    line-height: 1.42857143;
    text-align: left;
    min-width: 100px;
}

.table>tbody>tr>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table>thead>tr>th.SelectAllCheckbox {
    min-width: 30px;
}