.Modal {
    background: #fff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    width: 100%;
    max-width: 600px;
    padding: 30px;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    z-index: 1000;

    .CloseModal {
        text-align: right;

        .fa-times {
            font-size: 24px;
            cursor: pointer;
        }
    }
}