.Pager {
    padding-bottom: 30px;

    .PagerPrev, .PagerNext {
        float: left;
        margin-right: 8px;
        cursor: pointer;
        padding: 5px 10px;
        border: solid 1px #000;
        margin-bottom: 15px;
    }

    .PageCounter {
        float: left;

        li {
            list-style: none;
            display: inline-block;
            margin-right: 8px;
            cursor: pointer;
            padding: 5px 10px;
            border: solid 1px #000;
            margin-bottom: 15px;
        }

        .Selected {
            font-weight: 700;
            background: #000;
            color: #fff;
        }
    }
}