.Select {
    margin-bottom: 1rem;

    .SelectField {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        height: calc(1.5em + .75rem + 2px);
    }

    .InputErrorMessage p {
        color: red;
        margin: 0 0 8px 0;
    }
    
    .Required {
        color: red;
    }

    label {
        display: inline-block;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }
}