.BlockActions {
    width: 100%;
    text-align: right;
    padding: 10px 0;
    background: #fff;
    margin-top: 15px;

    button {
        background: none;
        box-shadow: none;
        border: none;
        cursor: pointer;
        margin: 0 10px;
    }

    .fa-arrow-up, .fa-arrow-down {
        cursor: pointer;
    }

    .fa-arrow-up {
        margin-right: -8px;
        margin-left: 8px;
    }

    .fa-arrow-down {
        margin-left: -8px;
        margin-right: 8px;
    }

    .fa-chevron-up, .fa-chevron-down {
        cursor: pointer;
        float: left;
        margin-left: 10px;
        margin-right: 10px;
    }
}