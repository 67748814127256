.DashboardItemWrapper {
    width: 50%;
    float: left;
    text-align: center;
    margin-top: 30px;

    @media(min-width: 992px) {
        width: 25%;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .DashboardItem {
        padding: 15px;
        background: #50BE4B;
        border-radius: 3px;
        font-size: 18px;
        margin: 0 15px;
        height: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;

        @media(min-width: 600px) {
            font-size: 24px;
        }
    }
}