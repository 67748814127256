.SidebarLeftWrapper {
    width: 100%;
    position: relative;

    @media(min-width: 1200px) {
        width: 20%;
        float: left;
    }

    .SidebarLeft {
        padding: 15px;
    }
}