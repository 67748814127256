.Header {
    background: #D3E0EB;
    padding: 15px 0;
    margin-bottom: 30px;
    z-index: 100;
    position: relative;

    .Nav {
        padding: 0;
        list-style: none;
        overflow: auto;
        margin: 0;

        .NavLeft {
            float: left;
            padding: 0;
            margin: 10px 0;
        }
    
        .NavRight {
            float: right;
            padding: 0;
            margin: 10px 0;
        }

        li {
            float: left;
            margin-right: 15px;
            list-style: none;

            a {
                color: inherit;
                text-decoration: none;
                font-size: 16px;
                font-weight: bold;
                padding-bottom: 5px;
            }

            a:hover {
                border-bottom: solid 3px #50BE4B;
            }
        }

        .Active {
            border-bottom: solid 3px #50BE4B;
        }

        .DropdownContainer {
            .NavDropdown {
                display: none;
            }
        }

        .DropdownContainer:hover {
            .NavDropdown {
                display: block;
            }
        }

        .NavDropdown {
            padding: 15px 0 0;
            background: #D3E0EB;
            padding: 10px;
            margin-left: -10px;
            position: absolute;

            li {
                display: block;
                padding: 10px 0;
                float: none;
            }
        }
    }

    .MobileToggle {
        text-align: right;

        button {
            background: transparent;
            border: none;

            .fa-bars, .fa-times {
                font-size: 30px;
                cursor: pointer;
            }
        }
    }

    .MobileNav {
        padding: 0;
        overflow: auto;
        margin: 0;

        .Hidden {
            display: none;
        }

        ul {
            list-style: none;
            padding-left: 20px;

            li {
                list-style: none;
                padding: 10px 0 5px;
    
                a {
                    color: inherit;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: bold;
                    padding-bottom: 5px;
                }

                li:first-child {
                    padding-top: 15px;
                }
            }

            .ToggleDropdown {
                float: right;
                margin-right: 20px;
                background: transparent;
                border: none;
            }

            .fa-chevron-right, .fa-chevron-down {
                font-size: 20px;
            }
    
            .Active {
                border-bottom: solid 3px #50BE4B;
            }
        }
    }
}