.ImagePreview {
    padding-bottom: 15px;

    img {
        max-width: 200px;
        height: auto;
        margin-bottom: 10px;
    }

    .btn-danger {
        padding: 5px;
        font-size: 14px;
    }
}