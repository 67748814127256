.ConditionalBlock {
    margin-bottom: 1rem;
    border: 2px solid #000;
    padding: 0;
    background: #fff;
    position: relative;

    .Hidden {
        display: none;
    }

    .fa-arrows-alt {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}

.Level-1, .Level-4 {
    border-color: #007DFF;
}

.Level-2, .Level-5 {
    border-color: #913FF9;
}

.Level-3, .Level-6 {
    border-color: #FF00E5;
}

.Header-Level-1, .Header-Level-4 {
    color: #007DFF;
}

.Header-Level-2, .Header-Level-5 {
    color: #913FF9;
}

.Header-Level-3, .Header-Level-6 {
    color: #FF00E5;
}