.Checkbox {
    label {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-left: 5px;
    }
}

.Checkbox.Level-2 {
    padding-left: 15px;
    display: none;
}

.Checkbox.Level-3 {
    padding-left: 30px;
    display: none;
}

.Checkbox.Show {
    display: block;
}