.ProductOption {
    margin-bottom: 1rem;
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.15);
    overflow: auto;
    position: relative;

    .TopBar {
        width: 100%;
        padding-bottom: 15px;

        .Type {
            font-weight: bold;
        }

        button {
            float: right;
            background: none;
            box-shadow: none;
            border: none;
            cursor: pointer;
        }

        .fa-arrow-up, .fa-arrow-down {
            cursor: pointer;
            float: right;
        }
    
        .fa-arrow-down {
            margin-left: 3px;
            margin-right: 10px;
        }
    
        .fa-arrow-up {
            margin-right: 3px;
        }
    }

    .Option {
        background: #eee;
        padding: 10px;
        overflow: auto;

        .TopBar {
            padding-bottom: 0;
            overflow: auto;
        }
    }

    .Option:nth-child(odd) {
        background: #D3E0EB;
    }

    .btn {
        margin-top: 30px;
    }
}

.HouseOption {
    .TopBar {
        overflow: auto;
        padding: 0;
    }

    .Select {
        width: calc(50% - 15px);
        float: left;
        margin-right: 15px;
    }

    .InputWrapper {
        width: 50%;
        float: left;
    }
}