.Message {
	padding: 15px;
    margin-bottom: 15px;
	border-radius: 5px;
    position: relative;
    overflow: auto;
    
    p {
        margin: 0
    }

    .MessageWrapper {
        width: 80%;
        float: left;
    }

    .CloseMessage {
        text-align: right;
        width: 20%;
        float: right;

        button {
            background: transparent;
            box-shadow: none;
            border: none;
            cursor: pointer;
        }
    }
}

.Success {
    background: lightgreen;
}

.Error {
    background: lightcoral;
}