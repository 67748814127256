.MainContentWrapper {
    width: 100%;

    @media(min-width: 1200px) {
        width: 75%;
        float: left;
    }

    .MainContent {
        padding: 15px;
    }
}

.MainContentWrapper.Narrow {
    width: 100%;
    
    @media(min-width: 1200px) {
        width: 55%;
    }
}