.TagWrapper {
    margin-bottom: 10px;

    .Tag {
        background: none;
        border: none;
        box-shadow: none;
        font-size: 18px;
        cursor: pointer;
    }

    .Tag:hover {
        color: #50BE4B;
    }
}