.OptionBlock {
    margin-bottom: 1rem;
    border-top: 2px solid #000;
    padding: 10px;
    background: #fff;

    .fa-chevron-right {
        margin: 0 10px;
        font-size: 14px;
    }

    .Hidden {
        display: none;
    }
}

.Level-1, .Level-4 {
    border-color: #007DFF;
}

.Level-2, .Level-5 {
    border-color: #913FF9;
}

.Level-3, .Level-6 {
    border-color: #FF00E5;
}