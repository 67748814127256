.SidebarWrapper {
    width: 100%;

    @media(min-width: 1200px) {
        width: 25%;
        float: left;
    }

    .Sidebar {
        padding: 15px;
        margin-top: 15px;
    }
}