.filteredItemsWrapper {
    position: relative;
    
    .filteredItems {
        border: solid 1px #000;
        margin-top: -1rem;
        padding: 0;
        position: absolute;
        z-index: 100;
        background: #fff;
        width: 100%;
    
        .itemWrapper {
            padding: 0 10px;
            position: relative;
    
            button {
                background: none;
                border: none;
                box-shadow: none;
                cursor: pointer;
                font-size: 18px;
                padding: 10px 0;
                width: 100%;
                text-align: left;
            }
        }
    
        .itemWrapper:hover {
            background: #eee;
        }
    }
}