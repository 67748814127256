.VideoBlock {
    background: #fff;
    margin-bottom: 1rem;
    position: relative;
    padding: 1px 10px;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.15);

    .Placeholder {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        height: 400px;
        background: #F3F3F3;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .UploadButton {
        display: none;
    }

    .fa-arrows-alt {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}