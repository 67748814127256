.TextArea {
    margin-bottom: 1rem;

    .TextAreaField {
        display: block;
        width: calc(100% - 1.5rem);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    label {
        display: inline-block;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }
}