.LinkBlock {
    margin-bottom: 1rem;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.15);
    padding: 0 20px 20px;
    background: #fff;
    position: relative;

    .fa-arrows-alt {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }

    .Checkbox {
        margin-bottom: 1rem;
    }
}